import { useEffect, useState } from "react";

const downloadResourcePack = async () => {
  await fetch("/adventure/resource-pack").then((response) => {
    response.blob().then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "adventure-resource-pack.zip";
      a.click();
    });
    window.location.href = response.url;
  });
};

export default function AdventureResourcePack() {
  const [downloaded, setDownloaded] = useState(false);
  useEffect(() => {
    if (!downloaded) {
      downloadResourcePack();
      setDownloaded(true);
    }
  });
  return (
    <div id="adventure-main">
      <p>downloading pack...</p>
    </div>
  );
}
